.banner-container {
     overflow: hidden;
     background: linear-gradient(
          90deg,
          rgba(0, 205, 251, 1) 0%,
          rgba(160, 0, 255, 1) 79%
     );
     width: 100%;
     margin: 0% 1%;
}

.scrolling-news {
     font-size: 17.33px;
     color: white;
     display: inline-block;
     letter-spacing: 9px;
     white-space: nowrap;
     padding-left: 100%;
     animation: scroll 71s infinite linear;
}

.scrolling-news span {
     font-weight: 100;
     display: inline-block;
}

@keyframes scroll {
     0% {
          transform: translate(0%);
     }
     100% {
          transform: translate(-100%);
     }
}

@media only screen and(max-width: 2024px) {
     .banner-container {
          position: relative;
          transform: translate(0%, -103%);
     }
}

/* @media only screen and (max-width: 960px) {
     .banner-container {
          height: 27px;
     }
     .scrolling-news span {
          font-size: 27px;
     }
} */

@media only screen and (max-width: 960px) {
     .banner-container {
          height: 2em;
          margin: auto;
     }
     .scrolling-news {
          margin: 0%;
          font-size: 27px;
     }
     .scrolling-news span {
          margin: auto;
     }
}
