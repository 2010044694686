.donate-container {
     margin-bottom: 1%;
     padding: 1%;
     display: flex;
}

.donate-container > summary::marker {
     content: none;
     /* cursor: pointer; */
}

.donate-container > summary > p {
     margin: 0;
     cursor: pointer;
     font-weight: 500;
}

.buttons-container {
     display: flex;
     flex-direction: row;
}

.paypal-text {
     letter-spacing: 11px;
     margin-top: 1%;
}

.card-paypal-container {
     display: flex;
     flex-direction: row;
     width: 100%;
     justify-content: space-around;
}

.flip-card {
     background-color: transparent;
     width: 300px;
     height: 300px;
     perspective: 1000px;
}

.flip-card-inner {
     position: relative;
     width: 100%;
     height: 100%;
     /* text-align: center; */
     transition: transform 1s;
     transform-style: preserve-3d;
     /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
}

.flip-card:hover .flip-card-inner {
     transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
     position: absolute;
     width: 100%;
     height: 100%;
     -webkit-backface-visibility: hidden;
     backface-visibility: hidden;
     -moz-backface-visibility: hidden;
}

.flip-card-front {
     /* background-color: #bbb; */
     color: black;
     /* visibility: hidden; */
}

.flip-card-back {
     text-align: center;
     letter-spacing: 7px;
     display: flex;
     flex-direction: column;

     /* background-color: #2980b9; */
     /* padding: 3%; */
     column-gap: 1%;
     /* color: black; */
     transform: rotateY(180deg);
}

@media only screen and (max-width: 480px) {
     .card-paypal-container {
          display: flex;
          flex-direction: column;
          align-items: center;
     }
     .donate-container {
          padding: 2.3%;
     }
     .flip-card-back img {
          visibility: hidden;
     }
}
