.card-container {
     /* margin: 2px 1; */
     margin: 1%;
     /* background-color: aqua; */

     width: 100%;
     /* height: 50px; */
}
.card-flyer-container {
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     margin-top: auto;
     /* height: 230px; */
     /* align-items: flex-end; */
}

.card-flyer {
     object-fit: contain;
     width: 111px;
     height: auto;
     padding: 0% 1% 0% 0%;
}

.card-container p {
     /* color: black; */
     margin: 0;
     font-size: 33px;
     font-weight: lighter;
     /* font-weight: bold; */
     letter-spacing: 13px;
}

.project-container {
     display: flex;
     flex-direction: column;
}

.project-name {
     border-style: dashed none none;
     margin-bottom: 1px;
}

.project-container {
     display: flex;
     flex-direction: column;
}

.project-content {
     display: flex;
     flex-direction: row;
     /* position: absolute; */
     /* transform: translate(0% 0%); */
     /* background: aqua; */
     /* width: 100vw; */
     padding: 1%;
     /* height: auto; */
     display: flex;
}

.project-content iframe {
     width: 50%;
     /* height: 100%; */
     border: none;
}

.yt-frame {
     height: 360px;
}

.snd-cloud-frame {
     height: 50%;
}

.project-description {
     display: flex;
     flex-direction: column;
     letter-spacing: 7px;
     font-size: 10px;
     width: 50%;
     padding: 1% 1% 0% 1%;
}

.project-description p {
     font-size: 21px;
}

.score-content > details > summary > p {
     font-size: 21px;
     padding: 1% 2%;
     margin: 0;
     cursor: pointer;
}

.score-content summary p:hover {
     font-size: 23px;
}

.score-content summary::marker {
     content: none;
     /* list-style-position: outside; */
     /* list-style-image: url("starsolid.gif"); */
}

.score-view {
     border: none;
     width: 100vw;
     height: 100vh;
}
/* 
.project-name:hover + .project-content {
     display: block;
     background: aqua;
     height: 50px;
} */

.concert-info {
     margin: 0;
     padding: 0%;
     letter-spacing: 5px;
     list-style: none;
}

.concert-info li {
     margin-bottom: 1%;
}

.score {
     width: 100% !important;
     height: 100vh;
}

.empty {
     display: none;
}

details {
     border: none;
}

summary {
     font-size: 21px;
     font-weight: 100;
     letter-spacing: 11px;
     cursor: pointer;
}

summary::marker {
     /* font-weight: 100; */
     background: url(https://37.media.tumblr.com/dc680f60a53d78d9b35998e57b3f801e/tumblr_n9tgn6hZar1t029v6o1_400.gif);
     background-clip: content-box;
     -webkit-background-clip: content-box;
     /* color: transparent; */
}

@media only screen and (max-width: 960px) {
     .project-content {
          display: flex;
          flex-direction: column;
     }
     .project-content iframe {
          width: 100%;
     }
     .project-description {
          width: 100%;
     }
}

@media only screen and (max-width: 480px) {
     .project-content {
          display: flex;
          flex-direction: column;
     }
     .project-content iframe {
          width: 100%;
     }
     .project-description {
          width: 100%;
     }
}
