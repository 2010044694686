.oneUp {
     position: absolute;
     z-index: 100;
     top: auto;
     left: auto;
}
main {
     display: flex;
     flex-direction: column;
     z-index: -1;
     /* background-color: white; */
}

#small-screen {
     display: none;
}

article {
     /* background-color: blueviolet; */
     display: flex;
     /* height: 1250px; */
}

.text-wrapper {
     padding: 0% 1%;
     display: flex;
     flex-direction: column;
     /* background-color: rgba(0, 0, 0, 0.673); */
     /* position: sticky; */
     top: 0px;
     /* z-index: 1; */
     /* mix-blend-mode: difference; */
     /* color: rgb(0, 0, 0); */
     /* height: 1250px; */
}

.presentation-container {
     display: flex;
     text-align: left;
     letter-spacing: 0.71em;
}
.presentation-text {
     font-size: 23px;
     line-height: 133%;
     letter-spacing: 15px;
}
.presentation-text p {
     margin: 0%;
}

.photo-wrapper {
     padding: 0% 1%;
     max-width: 100%;
     display: flex;
}

.photo-wrapper img {
     margin: 0%;
     object-fit: cover;
     width: 460px;
     /* filter: hue-rotate(90deg); */
}

.photo-wrapper img:hover {
     opacity: 0.5;
     filter: blur(10px);
}

.social-container {
     display: flex;
     margin-top: auto;
}

.social-logo {
     width: 33px;
     padding: 2px;
     filter: brightness(0.9) invert(0.7) sepia(0.5) hue-rotate(208deg)
          saturate(2000%);
}

.resize-logo {
     width: 100%;
}

.mail {
     font-size: 17px;
     font-weight: 400;
     margin-left: auto;
     text-decoration: none;
}

.mail p {
     /* background: url(https://37.media.tumblr.com/dc680f60a53d78d9b35998e57b3f801e/tumblr_n9tgn6hZar1t029v6o1_400.gif);
     background-clip: text;
     -webkit-background-clip: text;
     color: transparent; */
     letter-spacing: 3.14px;
     font-size: 500;
     font-weight: 100;
     cursor: pointer;
}

/* @media only screen and (max-width: 960px) {
     main {
          width: 100%;
     }
     .presentation-text {
          font-size: 20px;
          font-weight: 500;
     }
     .social-container a img {
          width: 75%;
     }
     .social-container a p {
          font-size: 9px;
     }
} */

@media only screen and (max-width: 960px) {
     #big-screen {
          display: none;
     }

     #small-screen {
          display: block;
     }

     .photo-wrapper img {
          width: 100%;
     }

     main {
          width: 100%;
     }

     .flip-wrapper {
          background-color: transparent;
          width: 100%;
          height: 512px;
          perspective: 1000px;
     }
     .presentation-wrapper {
          position: relative;
          width: 100%;
          height: 100%;
          transition: transform 1s;
          transform-style: preserve-3d;
     }

     .flip-wrapper:hover .photo-wrapper {
          transform: rotateY(180deg);
          opacity: 0.3;
          filter: blur(10px);
     }

     .flip-wrapper:hover .text-wrapper {
          display: flex;
          visibility: visible;
     }

     .photo-wrapper {
          position: absolute;
          width: 100%;
          height: 100%;
          padding: 0%;
     }
     .photo-wrapper img {
          object-fit: cover;
          width: 100%;
     }

     .text-wrapper {
          visibility: hidden;
          position: absolute;
          /* width: 100%;
          height: 100%;
          backface-visibility: hidden;
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden; */
     }

     .presentation-text {
          width: 100%;
          height: 512px;
          font-size: 15px;
          font-weight: 800;
          white-space: wrap;
     }

     .social-container {
          justify-content: center;
     }
     .social-container a img {
          width: 100%;
     }
     .social-logo {
          align-self: center;
          width: 30px;
     }
     .social-container a p {
          font-size: 13px;
          font-weight: 500;
     }
}
