.titleName {
     /* width: 90%;
     display: flex;
     flex-direction: row;
     text-align: left; */
     letter-spacing: 0.33em;
     /* margin-left: 5%; */
}
.letters {
     /* background-color: #5d00ff; */
     /* color: transparent; */
     /* color: black; */
     background-size: 10;
     font-weight: bold;
     font-size: 73px;
     margin: 0%;
     /* margin: 0% 2%; */
     /* padding: 0% 30%; */
}
