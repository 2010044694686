footer {
     margin: auto;
     width: 80%;
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     gap: 10px;
     align-items: center;
     /* justify-content: space-between; */
}

footer img {
     width: 100%;
     height: 100%;
     object-fit: contain;
}

.foo_flip-card {
     background-color: transparent;
     width: 131px;
     height: 131px;
     perspective: 1000px;
}

.foo_flip-card-inner {
     position: relative;
     width: 100%;
     height: 100%;
     transition: transform 1s;
     transform-style: preserve-3d;
}

.foo_flip-card:hover .foo_flip-card-inner {
     transform: rotateY(180deg);
}

.foo_flip-card-front,
.foo_flip-card-back {
     position: absolute;
     width: 100%;
     height: 100%;
     -webkit-backface-visibility: hidden;
     backface-visibility: hidden;
     -moz-backface-visibility: hidden;
     background-color: white;
}

.foo_flip-card-front {
     color: black;
}

.foo_flip-card-back {
     text-wrap: wrap;
     justify-content: center;
     align-items: center;
     text-align: center;
     display: flex;

     /* flex-grow: 1000px; */
     flex-direction: column;
     /* height: 100px; */
     font-size: 9px;
     letter-spacing: 4px;
     transform: rotateY(180deg);
}
