.App {
     /* text-align: center; */
     font-family: "Noto Sans Display", sans-serif;
}

p {
     font-weight: 100;
     background: url(https://37.media.tumblr.com/dc680f60a53d78d9b35998e57b3f801e/tumblr_n9tgn6hZar1t029v6o1_400.gif);
     background-clip: text;
     -webkit-background-clip: text;
     color: transparent;
     cursor: auto;
}
